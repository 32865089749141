import React, { useState } from "react";

import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { RecoilRoot } from "recoil";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Contact } from "./components/contact";
import { Health } from "./components/health";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
  offset: 80, // 추가적인 보정값, nav 사이즈가 80 이기에 (음수도 가능)
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState(JsonData);

  // useEffect(() => {
  //   setLandingPageData(JsonData);
  // }, [landingPageData]);

  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Contact data={landingPageData.Contact} />}
          />
          <Route path="/health" element={<Health />} />
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
};

export default App;
